<template>
    <slot>
        <div class="mb-1">
            <label class="form-label" for="paymentMode">Mode of Payment</label>
            <v-select
                placeholder="Select Cash & Bank Account"
                class="w-100"
                v-model="formData.mop_account_head_id"
                name="mop_account_head_id"
                :options="cashAndBank"
                label="name"
                :reduce="name => name.id"
            />
        </div>
        <div class="mb-1">
            <label class="form-label" for="mopReference">MoP Reference</label>
            <vField
                v-model="formData.receipt_reference"
                name="receipt_reference"
                type="text"
                class="form-control"
            />
        </div>
        <div class="row mb-1">
            <div class="col-12 col-md-6">
                <label class="form-label" for="receiptAmount">Net Payable</label>
                <vField
                    v-model="props.grandTotal"
                    name="total"
                    type="number"
                    class="form-control text-right"
                    disabled="disabled"
                />
            </div>
            <div class="col-12 col-md-6">
                <label class="form-label" for="receiptAmount">Paid Amount</label>
                <vField
                    v-model="formData.payment"
                    name="amount"
                    type="number"
                    class="form-control text-right"
                    @keyup="validatePaymentAmount"
                />
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-12 col-md-6">
                <label class="form-label" for="receivedAmount">Total Collection</label>
                <vField
                    v-model="receivedAmount"
                    name="receivedAmount"
                    type="number"
                    class="form-control text-right"

                />
            </div>
            <div class="col-12 col-md-6 d-flex align-items-end">
                <h4>Change Amount: <b>{{ changeAmount }} BDT</b></h4>
            </div>
        </div>
    </slot>
</template>

<script setup>
import {computed, inject, onMounted, ref, watchEffect} from "vue";
import {useRoute} from "vue-router";
import handleReceipt from "@/services/modules/receipt";

const $router = useRoute();
const {fetchCashAndBank} = handleReceipt();
const formData = inject('formData')
const showError = inject('showError')
const props = defineProps({
    grandTotal: {
        type: Number
    },
    isHospitalInvoice: {
        type: Boolean,
        default: false
    }
})

const companyId = $router.params.companyId;
const cashAndBank = ref([])
const receivedAmount = ref(null)

const setInitialValue = (array, field) => {
    if(!array.length) return;
    formData.value[field] = array[0].id
}

watchEffect(() => {
    formData.value.payment = 0;
    if(!props.grandTotal || !props.isHospitalInvoice) return;
    formData.value.payment = props.grandTotal;
});

const totalAmount = computed(() => props.grandTotal)

const validatePaymentAmount = (event) => {
    const amount = event.target.value
    if (amount < 0) {
        showError("Payment Amount cannot be less than 0")
    }

    if (amount > totalAmount.value) {
        formData.value.payment = totalAmount.value
    }
}

const changeAmount = computed(() => {
    if (formData.value.payment > 0 && receivedAmount.value && receivedAmount.value > 0) {
        return receivedAmount.value - formData.value.payment;
    }
    return 0
})

const generateMoneyReceiptNumber = async () => {
    setInitialValue(cashAndBank.value, 'mop_account_head_id');
    receivedAmount.value = null;
}

onMounted(() => {
    Promise.all([
        fetchCashAndBank(companyId).then(res => {
            if (res.data) cashAndBank.value = res.data
        }),
        generateMoneyReceiptNumber(),
    ]).then(() => {
        setInitialValue(cashAndBank.value, 'mop_account_head_id');
    })
    .catch(e => {})
})

defineExpose({generateMoneyReceiptNumber})
</script>
