<template>
  <slot>
    <div class="mb-1">
      <label class="form-label">Money Receipt No</label>
      <div class="d-flex align-items-center justify-content-md-end mb-1">
        <div class="input-group input-group-merge invoice-edit-input-group">
          <div class="input-group-text">
            <span>{{ paymentPrefix }}-</span>
          </div>
          <input type="number" min="1" class="form-control invoice-edit-input" placeholder=""
                 v-model="paymentSerial" :disabled="isDisabled">
        </div>
      </div>
    </div>
    <div class="mb-1">
      <label class="form-label" for="paymentMode">Mode of Payment</label>
      <v-select
        placeholder="Select Cash & Bank Account"
        class="w-100"
        v-model="formData.mop_account_head_id"
        name="mop_account_head_id"
        :options="cashAndBank"
        label="name"
        :reduce="name => name.id"
        :disabled="isDisabled"
      />
    </div>
    <div class="mb-1">
      <label class="form-label" for="mopReference">MoP Reference</label>
      <vField
        v-model="formData.receipt_reference"
        name="receipt_reference"
        type="text"
        class="form-control"
        :disabled="isDisabled"
      />
    </div>
    <div class="mb-1">
      <label class="form-label" for="receiptAmount">Net Payable</label>
      <vField
        v-model="props.grandTotal"
        name="total"
        type="number"
        class="form-control text-right"
        disabled="disabled"
      />
    </div>
    <div class="mb-1">
      <div class="d-flex gap-1">
        <div>
          <label class="form-label" for="receiptAmount">Paid Amount</label>
          <vField
            v-model="formData.payment"
            name="amount"
            type="number"
            class="form-control text-right"
            @keyup="validatePaymentAmount"
            :disabled="isDisabled"
          />
        </div>
        <div v-if="props.totalReturnAmount">
          <label class="form-label" for="receiptAmount">Return Amount</label>
          <vField
            v-model="props.totalReturnAmount"
            name="amount"
            type="number"
            class="form-control text-right"
          />
        </div>
      </div>
    </div>
  </slot>
</template>

<script setup>
import {computed, inject, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import handleReceipt from "@/services/modules/receipt";
import {editVoucherNumber, generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import {isDisturbed} from "form-data";


const $router = useRoute();
const {fetchCashAndBank} = handleReceipt();
const formData = inject('formData')
const showError = inject('showError')
const props = defineProps({
  grandTotal: {
    type: Number
  },
  totalReturnAmount: {
    type: Number,
    default: null
  },
  isDisabled: {
    type: Boolean,
    default: false
  }
})

const companyId = $router.params.companyId;
const cashAndBank = ref([])
let paymentPrefix = ref('')
let paymentSerial = ref('')

const setInitialValue = (array, field) => {
  if(!array.length) return;
  formData.value[field] = array[0].id
}

const totalAmount = computed(() => props.grandTotal)
const receiptNumber = computed(() => formData.value.money_receipt_no)

const validatePaymentAmount = (event) => {
  const amount = event.target.value
  if (amount < 0) {
    showError("Payment Amount cannot be less than 0")
  }

  if (amount > totalAmount.value) {
    formData.value.payment = totalAmount.value
  }
}

watch(totalAmount, (newValue, oldValue) => {
  if (newValue) {
    formData.value.payment = newValue
  }
})

watch(receiptNumber, (newValue) => {
  if(newValue) {
    let voucher = editVoucherNumber(newValue)
    paymentPrefix.value = voucher.prefix;
    paymentSerial.value = voucher.serial;
  }
})

onMounted(() => {
  const txnQuery = `?company_id=${companyId}&voucher_type=receipt_voucher&txn_type=invoice_receipt`;
  Promise.all([
    fetchCashAndBank(companyId).then(res => {
      if (res.data) cashAndBank.value = res.data
    })
  ]).then(() => {
    setInitialValue(cashAndBank.value, 'mop_account_head_id');
  })
    .catch(e => {})
})
</script>